.Home {
}

.HomeTopSection {
    color: white;
    height: 800px;
    display: flex;
}

.HomeHeader {
    font-size: 80px;
    font-weight: 500;
    width: 80%;
    margin: auto;
    margin-top: 200px;
    padding-bottom: 50px;
    border-bottom: 1px solid rgb(255, 255, 255);
}

.DataAIAgent {
    font-family: sans-serif;
    display: inline-block;
    background-color: white;
    border: 1px rgb(243, 243, 243) solid;
    box-shadow: 10px 10px 20px #8c8c8c;
    border-radius: 5px;
    width: 88.5%;
    margin: auto;
    padding: 40px;
    margin-top: 50px;
    font-size: 20px;
}

.DataAIAgentArrowRight {
    cursor: pointer;
    margin-top: 16vw;
    margin-left: 7vw;
}

.DataAIAgentRight {
    width: 20%;
    float: right;
    padding-right: 400px;
    display: inline-flex;
}

.DataAIAgentArrowLeft {
    cursor: pointer;
    margin-top: 15vw;
    margin-right: 3vw;
}

.DataAIAgentLeft {
    display: inline-flex;
    text-align: left;
    width: 40%;
    float: left;
    padding: 20px;
}

.DataAIAgentHeader {
    font-size: 50px;
    font-weight: 500;
}

.DataAIAgentText {
    transition-duration: 5s;
    transition: all .6s;
    font-size: 26px;
    font-weight: 300;
    line-height: 1.5;
}

.Ecosystem {
    background-color: white;
    border: 1px rgb(243, 243, 243) solid;
    box-shadow: 10px 10px 20px #8c8c8c;
    border-radius: 5px;
    width: 88.5%;
    margin: auto;
    padding: 40px;
    margin-top: 50px;
    margin-bottom: 50px;
    line-height: 1.5;
    text-align: left;
    font-size: 23px;
    font-weight: 300;
}

.EcosystemHeader {
    font-size: 50px;
    font-weight: 500;
}

.EcosystemLeft {
    display: inline-flex;
    padding: 35px;
}

.EcosystemArrowLeft {
    cursor: pointer;
    margin-top: 14vw;
    margin-right: 3vw;
}

.EcosystemArrowRight {
    cursor: pointer;
    margin-top: 16vw;
    margin-right: 1vw;
    margin-left: 3vw;
}

.SmartID {
    background-color: white;
    border: 1px rgb(243, 243, 243) solid;
    box-shadow: 10px 10px 20px #8c8c8c;
    border-radius: 5px;
    width: 88.5%;
    margin: auto;
    padding: 40px;
    padding-top: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 20px;
}

.SmartIDHeader {
    padding: 0;
    margin-bottom: 10px;
    font-size: 50px;
    font-weight: 500;
    color: #000000;
}

.SmartIDText {
    font-size: 23px;
    font-weight: 300;
    line-height: 1.5;
}