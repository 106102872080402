
#Signup-Root {
    height: 100%;
    margin-top: 40px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;

    display: flex;
    flex-direction: row;
    vertical-align: top;
}

#Left-Sidebar {
    height: 100%;
    width: 30%;
    
    display: flex;
    flex-direction: column;
    gap: 15px;
}

#Signup-Progress-Root {
    height: 100%;
    margin: 20px;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;
}

#Signup-Progress-Header {
    width: 100%;
    font-size: 200%;
}

#Progress-Bar-Root {
    width: 100%;

    display: flex;
    flex-direction: row;
}

#Progress-Bar-Wrapper {
    width: 10%;
    height: 100%;
    padding: 8px;
    margin-top: 10px;
    margin-left: -10px;

    transform: rotate(90deg);
}

#Progress-Bar {
    width: 100px;
    padding: 5px;

    border-radius: 5px;
}

#Info-Panel-Header {
    width: 100%;
    margin: 20px;

    font-size: 170%;
    text-align: left;
}

#Info-Panel-Text {
    padding: 20px;

    text-align: left;
}

#Signup-Page-Root {
    height: 100%;
    width: 75%;
    padding-left: 30px;

    text-align: start;
}

/* #Signup-Page-Head {
    padding: 0px 0px 5px 0px;

    align-items: center;

} */

#Skip-Page-Button {
    height: 60%;
    padding: 5px 15px;

    color: #FDBF18;
}

#Nav-Button-Container {
    margin-bottom: auto;
    padding: 60px 0px 5px 0px;
}

.Nav-Button {
    padding: 10px 20px;

    border-radius: 30px;

    background-color: #3658A7;
    color: white;
}

