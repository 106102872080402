.Partners {
}

.ParterTopSection{
    height: 650px;
}

.PartnerHeading {
    color: white;
    font-size: 70px;
    text-align: center;
    margin: auto;
    padding-top: 120px;
    padding-bottom: 100px;
    font-weight: 500;
}

.PartnerSection {
    display: inline-flex;
    flex-wrap: wrap;
    width: 95%;
    margin: auto;
}

.PartnerEntry {
    background-color: white;
    margin: 40px;
    border: 1px #ebebeb solid;
    box-shadow: 10px 10px 20px #8c8c8c;
    border-radius: 5px;
    width: 260px;
    height: 150px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.PartnerImage {
    width: 80%;
    height: auto;
}