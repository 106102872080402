.Header {
    width: 100%;
    margin: none;
    padding: none;
    background-color: rgb(255, 255, 255);
    height: 6vw;
    box-shadow: 0px 5px 20px rgb(153, 153, 153);
}

.NavbarLogo {
    float: left;
    margin: 1.2vw 0vw 0vw 1vw;
}

.NavbarLinks {
    text-align: left;
    margin-left: 54vw;
}

.NavbarButton {
    margin: 1.7vw 1vw .6px 1vw;
    border: none;
    background-color: transparent;
    padding: .7vw;
    color: black;
    font-size: 1.1vw;
    transition-duration: .4s;

    background-image: linear-gradient(#ffde22, #ffde22);
    background-size: 0% .1em;
    background-position-y: 100%;
    background-position-x: 0%;
    background-repeat: no-repeat;
}

.NavbarButton:hover {
    cursor: pointer;
    background-size: 100% .1em;
    background-image: linear-gradient(#ffde22, #ffde22);
    color: rgb(0, 83, 151);
}