.container {
    height: 75px;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
  }
  
  .character {
    line-height: 75px;
    font-size: 32px;
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    border: 1px solid black;
    border-radius: 8px;
    margin-left: 12px;
    padding: 0px -5px;
    font-weight: bolder;
  }
  
  .character:first-child {
    /* margin-left: 0; */
  }
  
  .character--inactive {
    background-color: rgba(0, 0, 0, 0);
    padding: 0px -5px;

  }
  
  .character--selected {
    border: 1px solid rgb(54, 88, 167);
    /* padding: 0px 15px; */
    animation-name: pulse;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;

  }

  @keyframes pulse {
    from {
      transform: scale(1);
    }
  
    to {
      transform: scale(1.1);
    }
  }