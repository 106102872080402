html {
    scroll-behavior: smooth;
    background-color: rgb(231, 231, 231);
}

.Donate {
    width: 100%;
    margin: auto;
}

.Heading {
    width: 80%;
    text-align: center;
    margin: auto;
    font-size: 10vw;
    font-weight: 500;
    padding-bottom: 70px;
    border-bottom: 1px solid black;
}

.SubHeading {
    width: 80%;
    text-align: center;
    margin: auto;
}

.PayPalDonationSection{
    margin-top: 70px;
}

#Donate-Button{
    font-family: inherit;
    font-size: 30px;
    border-width: 0px;
    height:50px;
    width: 300px;
    border-radius: 25px;
    transition-duration: 0.4s;
    transition-delay: 50ms;
    background-color:rgb(255, 196, 57);
    cursor: pointer;
}
#Donate-Button:hover{
    background-color:rgb(255, 196, 57);
    transform: scale(110%);
}

#Field-Label {
    font-size: 18px;
}

#Copy-Button {
    font-family: inherit;
    font-size: 18px;
    border-width: 0px;
    height:50px;
    width: 250px;
    border-radius: 25px;
    transition-duration: 0.4s;
    background-color: rgb(230, 236, 242);
    cursor: pointer;
}
#Copy-Button:hover {
    background-color:rgb(51, 153, 255);
    color: white;
}
#Copy-Button:active {
    background-color:rgb(128, 191, 255);
    transition-duration: 0.1s;
    transform: scale(125%);
    color: white;
}

:focus {
    outline: none;
}

.Crypto-Parent-Button {
    margin: 20px;
    border-radius: 10px; 
    height: 180px;
    width: 180px;
    /* overflow: hidden; */
    background-color: #ffffff;
    cursor: pointer;
    /* vertical-align: top;
    border-color: attr(someColor); */
    /* position: absolute; */
    transition-duration: 0.2s;
}

.Crypto-Parent-Button:hover {
    transform: scale(1.3);
}

.Crypto-Button-Label {
    border-radius: 8px;
    padding: 10px;
    color: #FFFFFF;
    width: auto;
    transition: all .6s;
    font-weight: bolder;
    font-size: 135%;
    position: relative;
    z-index: 1;
}

/* .Crypto-Button-Label:before {
    z-index: -2;
    content: "";
    border-radius: 8px;
    background-color: rgb(18, 230, 230);
    width: auto;
    height: 15%;
    position: relative;
    padding: 10px;
    display: block;
    transform-origin: 50% 50%;
    transition: all .6s;
    transition-duration: .6s;
} */

.label-text {
    position: relative;
    /* transition: all .6s; */
    color: #FFFFFF;

}

/* .Crypto-Parent-Button:hover .Crypto-Button-Label:before {
    transform: scale(800%);
} */




/* .inner {
    border-radius: 8px;
    width: auto;
    position: relative;
} */

.TextBoxPayPalDonate {
    width: 80%;
    margin: auto;
    border: 1px rgb(243, 243, 243) solid;
    box-shadow: 10px 10px 20px #8c8c8c;
    border-radius: 5px;
    background-color: white;
    padding: 20px;
    text-align: center;
}

.TextBoxPayCryptoDonate {
    width: 80%;
    margin: auto;
    border: 1px rgb(243, 243, 243) solid;
    box-shadow: 10px 10px 20px #8c8c8c;
    border-radius: 5px;
    background-color: white;
    padding: 20px;
    text-align: center;
}

.TextBoxSubSection {
    width: 80%;
    margin: auto;
    border: 1px rgb(243, 243, 243) solid;
    box-shadow: 10px 10px 20px #8c8c8c;
    border-radius: 5px;
    background-color: white;
    padding: 40px;
    font-size: 20px;
    text-align: left;   
}

.FAQEntry {
    transition-duration: 2s;
}

.FAQTitle {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}
