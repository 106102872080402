
.CryptoSearchBar {
    text-align: center;
    border: 1px solid rgb(202, 202, 202);
    border-radius: 5px;
    margin: auto;
    padding: 0px;
    width: 80%;
    height: 440px;
    transition-duration: .2s;
}

.CryptoSearchBarInput {
    transition-duration: .2s;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
    height: 30px;
    font-size: 20px;
    border: 1px solid rgb(202, 202, 202);
    border-radius: 5px;
}

.CryptoSearchBarInput:hover {
    border: 1px solid rgb(0, 0, 0);
    cursor: pointer;
}

.RequestsList {
    transition-duration: .2s;
    border-top: 1px solid rgb(202, 202, 202);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 220px;
    margin-top: 10px;
    padding: 0px;
    padding-top: 10px;
    width: 90%;
    height: 10px;
}

.RequestsEntry {
    border: 0px;
    margin: 4px 0px 0px 0px;
    padding: 0px;
}

.CryptoLogo {
    margin-left: 10px;
    float: left;
    height: 50px;
}

.CryptoButton {
    font-size: 20px;
    font-weight: 700;
    color: white;
    text-align: center;
    transition-duration: .2s;
    border: 0px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    border-radius: 5px;
    width: 100%;
    height: 60px;
    background-color: white;
    border: 1px solid rgb(223, 223, 223);
}

.CryptoButtonNameText {
    float: left;
    text-align: center;
    margin: 12px 0px 0px 20px;
}

.CryptoButtonSourceName {
    float: right;
    margin: 12px 50px 0px 0px;
}

.CryptoButton:hover {
    color: rgb(0, 0, 0);;
    font-size: 25px;
    font-weight: 750;
    border: 1px solid rgb(0, 0, 0);
    cursor: pointer;
    transform: scale(1.05);
}

.CryptoCopySection {
    width: 80%;
    margin: auto;
}