#Donation-Pop-Up-Container {
    background-color: rgb(255, 255, 255);
    border-width: 0px;
    border-radius: 25px;
    width: 480px;
    padding: 25px;
    position: fixed;
    right: 2rem;
    bottom: 5rem;
    transform: translate(0%, 0%);
    z-index: 1;
    font-family: inherit;
    font-size: 18px;
    box-shadow: 0px 0px 4px rgba(153, 153, 153, 0.75);
    transition-duration: 0.4s;
}
#Donation-Pop-Up-Container:hover {
    transform: scale(102.5%);
}
#Page-Dark-Cover {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(153, 153, 153, 0.5);
    z-index: 1;
    display: none;
}
.buttons {
    font-family: inherit;
    font-size: 18px;
    border-width: 0px;
    height:50px;
    width: 250px;
    border-radius: 25px;
    transition-duration: 0.4s;
    margin: 10px;
    background-color: rgb(230, 236, 242);
    cursor: pointer;
}
#Consider-Donate-Button {
    background-color: rgb(51, 153, 255);
    color: white;
}
#Consider-Donate-Button:hover {
    background-color:rgb(128, 191, 255);
    color: white;
}
.buttons:active {

    transition-duration: 0.1s;
    transform: scale(125%);

}
#Close-Donation-Pop-Up-Button:hover {
    background-color:rgb(255, 128, 128);
    color: white;
}